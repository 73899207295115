import { CommonModule } from '@angular/common';
import { ApplicationModule, Component } from '@angular/core';
import { RouterModule, RouterOutlet } from '@angular/router';
import { NavigationLink } from '../../core/models/NavigationLink';
import { MatIconModule } from '@angular/material/icon';
import { UserLoginInfoComponent } from "../../components/user-login-info/user-login-info.component";
import { AuthService, FeatureFlagService, role } from '@stactize/domain';
import { FeatureFlags } from '../../core/constants/feature-flag.const';
import { combineLatest, map, Observable, of } from 'rxjs';
import { MatMenuModule } from '@angular/material/menu';


@Component({
    selector: 'app-nav-layout',
    imports: [
        CommonModule, RouterOutlet, RouterModule, MatIconModule, UserLoginInfoComponent, ApplicationModule, MatMenuModule
    ],
    templateUrl: './nav-layout.component.html',
    styleUrl: './nav-layout.component.scss'
})
export class NavLayoutComponent {

  public filteredLinks$: Observable<NavigationLink[]>;

  constructor(authService: AuthService, featureFlagService: FeatureFlagService) {

    this.filteredLinks$ = combineLatest([authService.getUser(), featureFlagService.getFeatureFlags(), of(this.navigationLinks)])
      .pipe(
        map(([user, featureFlags, links]) => {
          return links.filter(link => {
            //Item is enabled by default if no flag set - otherwise fetch the flag and set the value
            const flagEnabled = link.featureFlag ? featureFlags.find(x => x.name === link.featureFlag)?.isEnabled : true;
            //If no required roles, user has access by default. Otherwise check whether the user has the required role
            const userHasAccess = link.roles ? link.roles.some(role => user.roles?.includes(role)) : true;

            return flagEnabled && userHasAccess;
          })
        })
      );
  }

  private navigationLinks: NavigationLink[] = [
    {
      route: '/dashboard',
      displayName: 'Home',
      icon: 'home'
    },
    {
      route: '/publishers',
      displayName: 'Publishers',
      icon: 'perm_identity',
      featureFlag: FeatureFlags.uiPagePublisher,
      roles: [role.admin],
    },
    {
      route: '/applications',
      displayName: 'Applications',
      icon: 'view_module',
      featureFlag: FeatureFlags.uiPageApplication,
    },
    {
      route: '/subscriptions',
      displayName: 'Subscriptions',
      icon: 'assignment',
      featureFlag: FeatureFlags.uiPageSubscription,
    },
    {
      route: '/private-offer',
      displayName: 'Private Offers',
      icon: 'work_outline',
      featureFlag: FeatureFlags.uiPagePrivateOffer,
    }
  ];
}

import { ApplicationConfig, inject, provideAppInitializer } from '@angular/core';
import { provideRouter } from '@angular/router';
import { environment } from '../environments/environment';
import { routes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideHttpClient } from '@angular/common/http';
import { APPINSIGHTS_CONNECTIONSTRING, FeatureFlagService, initializeFeatureFlags, provideFullstory } from '../../../domain/src/public-api';
import { BASE_PATH } from 'sdk/stactize-portal-bff-sdk';
import { provideLuxonDateAdapter } from '@angular/material-luxon-adapter';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideAnimationsAsync(),
    provideHttpClient(),
    {
      provide: APPINSIGHTS_CONNECTIONSTRING,
      useValue: environment.applicationInsights.connectionString
    },
    provideAppInitializer(() => {
        const initializerFn = (initializeFeatureFlags)(inject(FeatureFlagService));
        return initializerFn();
      }),
    provideFullstory({
      orgId: environment.fullstory.orgId,
      devMode: !environment.fullstory.isEnabled
    }),
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB'},
    provideLuxonDateAdapter(),
    { provide: BASE_PATH, useValue: '.' }, //Base Path for SDK is root
    //Set material form fields to have their labels at the top of the input
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { floatLabel: 'always' } }
  ]
};

import { SnippetOptions } from "@fullstory/browser";
import { FullstoryService } from "../services/fullstory/fullstory.service";
import { EnvironmentProviders, InjectionToken, makeEnvironmentProviders, inject, provideAppInitializer } from "@angular/core";

// APP_INITIALIZER that initialises fullstory service on startup
function initializeFullStory(fullstoryService: FullstoryService, config: SnippetOptions): () => Promise<any> {
    return () => fullstoryService.initialize(config);
}

// Injection token that allows us to inject the configs into the factory method
const FULLSTORY_CONFIG = new InjectionToken<SnippetOptions>('fullstory_config');

export function provideFullstory(fsConfig: SnippetOptions): EnvironmentProviders {
    return makeEnvironmentProviders([
        {
            provide: FULLSTORY_CONFIG,
            useValue: fsConfig
        },
        provideAppInitializer(() => {
        const initializerFn = (initializeFullStory)(inject(FullstoryService), inject(FULLSTORY_CONFIG));
        return initializerFn();
      })
    ]);
}